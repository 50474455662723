<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Edit Credit page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.name_ua"></el-input> 
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Link Description</p>
                        <el-form-item prop="data.link_dec_ua">
                            <el-input v-model="Pages.data.link_dec_ua"></el-input> 
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_ua">
                            <el-input v-model="Pages.data.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Step 2</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.step2.title_ua">
                            <el-input v-model="Pages.data.step2.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.step2.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step2.description_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>1 Button</p>
                        <el-form-item prop="data.step2.button1_ua">
                            <el-input v-model="Pages.data.step2.button1_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.step2.link1">
                            <el-input v-model="Pages.data.step2.link1"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>2 Button</p>
                        <el-form-item prop="data.step2.button2_ua">
                            <el-input v-model="Pages.data.step2.button2_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.step2.link2">
                            <el-input v-model="Pages.data.step2.link2"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Step 4</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.step4.title_ua">
                            <el-input v-model="Pages.data.step4.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.step4.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step4.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Phone</p>
                        <el-form-item prop="data.step4.phone">
                            <el-input v-model="Pages.data.step4.phone"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Phone Text</p>
                        <el-form-item prop="data.step4.title_ua">
                            <el-input v-model="Pages.data.step4.phone_title_ua"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>1.Email </p>
                        <el-form-item prop="data.step4.mail1">
                            <el-input v-model="Pages.data.step4.mail1"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>2.Email </p>
                        <el-form-item prop="data.step4.mail2">
                            <el-input v-model="Pages.data.step4.mail2"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>3.Email </p>
                        <el-form-item prop="data.step4.mail3">
                            <el-input v-model="Pages.data.step4.mail3"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Adress</p>
                        <el-form-item prop="data.step4.adress_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step4.adress_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.step4Image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'step4Image')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.step4Image&&Pages.files.step4Image.name? Pages.files.step4Image.name: Pages.files.step4Image ? Pages.files.step4Image :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                       <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_en">
                            <el-input v-model="Pages.data.name_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Link Description</p>
                        <el-form-item prop="data.link_dec_en">
                            <el-input v-model="Pages.data.link_dec_en"></el-input> 
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_en">
                            <el-input v-model="Pages.data.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Step 2</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.step2.title_en">
                            <el-input v-model="Pages.data.step2.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.step2.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step2.description_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>1 Button</p>
                        <el-form-item prop="data.step2.button1_en">
                            <el-input v-model="Pages.data.step2.button1_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>2 Button</p>
                        <el-form-item prop="data.step2.button2_en">
                            <el-input v-model="Pages.data.step2.button2_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Step 4</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.step4.title_en">
                            <el-input v-model="Pages.data.step4.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.step4.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step4.description_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Phone Text</p>
                        <el-form-item prop="data.step4.title_en">
                            <el-input v-model="Pages.data.step4.phone_title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Adress</p>
                        <el-form-item prop="data.step4.adress_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.step4.adress_en"></el-input>
                        </el-form-item>
                    </div>

                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div>
            </el-form>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/getcredit",
                data: {
                    name_ua: '',
                    name_en: '',
                    link_dec_ua:'',
                    link_dec_en:'',
                    title_ua:'',
                    title_en:'',
                    description_ua:'',
                    description_en:'',
                    step2:{
                        title_ua:'',
                        title_en:'',
                        description_ua:'',
                        description_en:'',
                        button1_ua:'',
                        button1_en:'',
                        link1:'',
                        button2_ua:'',
                        button2_en:'',
                        link2:''
                    },
                    step4:{
                        title_ua:'',
                        title_en:'',
                        description_ua:'',
                        description_en:'',
                        phone:'',
                        phone_title_ua:'',
                        phone_title_en:'',
                        mail1:'',
                        mail2:'',
                        mail3:'',
                        adress_ua:'',
                        adress_en:'',
                    } 


                },
                files: {
                    step4Image: '',
                }
            },
            PagesRules: {
                  data: {
                    name_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    name_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    title_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    title_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    description_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    description_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    step2:{
                        title_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        button1_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        button1_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        link1:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        button2_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        button2_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        link2:''
                    },
                    step4:{
                        title_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        phone:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        phone_title_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        phone_title_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        mail1:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        mail2:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        mail3:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        adress_ua:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        adress_en:[{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    } 


                },
                files: {
                    step4Image: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                }
                
                
            },
        }

    },

    methods: {
        UploadIMage(event, name) {
            this.Pages.files[`${name}`] = event.target.files[0]
        },
        PostStock() {

            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let files = new FormData();
                    var pageData = {}
                    if (typeof this.Pages.data != 'string') {
                        pageData = JSON.stringify(this.Pages.data)
                    }
                    files.append('key', this.Pages.key)
                    files.append('data', pageData)
                    for (let i of Object.keys(this.Pages.files)) {
                        if (this.Pages.files[i].name) {
                            files.append(`files[${i}]`, this.Pages.files[i]); 
                        }
                    }

                    this.axios.post(this.Pages.id? `/admin/statpage/${this.Pages.id}`:'/admin/statpage', files).then(res => {
                            console.log(res)
                            this.$router.push('/admin/statpages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                            this.$store.dispatch("get_statpages");
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    console.log("AAA")
                }
            })

        },
        GetPages() {
            this.loading=true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/getcredit'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
